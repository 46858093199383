import { fields } from "./fields";

export const filtersElements = [
  {
    value: fields.FILTER_NUMBER,
    title: "№ описи дел",
    type: "autocomplete",
    showSelectedKey: "titleValue",
    autocompleteActionName: "getInvAutocompleteByNumber",
    width: 285,
  },
  {
    value: fields.FILTER_OIK,
    title: "ОИК",
    type: "autocomplete",
    showSelectedKey: "code",
    autocompleteActionName: "getOikAutocompleteItems",
    width: 285,
  },
  {
    value: fields.FILTER_FUND,
    title: "Фонд",
    type: "select",
    selectItemsField: "fundNames",
    isShowDeletedIcon: true,
    width: 285,
  },
  {
    value: fields.CREATE_DATE_TIME,
    title: "Дата поступления в ЦХЭД",
    dateValues: [fields.FILTER_CREATE_DATE_TIME_FROM, fields.FILTER_CREATE_DATE_TIME_TO],
    type: "datePeriod",
    width: 244,
  },
  {
    value: fields.FILTER_YEAR,
    title: "Год",
    type: "year",
    width: 120,
  },
  {
    value: fields.FILTER_STATUS,
    title: "Статус",
    type: "select",
    selectItemsField: "statusesResultList",
    width: 285,
  },
  {
    value: fields.FILTER_HEADER,
    title: "Заголовок",
    type: "text",
    width: 285,
  },
  {
    value: fields.FILTER_CATEGORY,
    title: "Вид",
    tooltip: "Выбор из следующих вариантов: Больше или равно 10 лет, Постоянно, По личному составу",
    type: "select",
    selectItemsField: "categories",
    width: 285,
  },
  {
    value: fields.FILTER_AVAILABLE_ACTION,
    title: "Доступные действия",
    type: "select",
    selectItemsField: "availableAction",
    width: 285,
  },
  {
    value: fields.FILTER_KIND,
    title: "Тип",
    tooltip: "Выбор из следующих вариантов: Передача документов, Изменение сроков хранения документов",
    type: "select",
    selectItemsField: "kinds",
    width: 285,
  },
  {
    value: fields.FILTER_TYPE,
    title: "Тип проекта описи",
    type: "select",
    selectItemsField: "types",
    width: 285,
  },
];
